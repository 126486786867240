












































































import { Component, Vue } from "vue-property-decorator";
import Mixin from "@/js/mixins";
import { ImagePreview } from "vant";

@Component({ mixins: [Mixin] })
export default class PayDetail extends Vue {
  public user_id = "";
  public order_id = ""; // 订单id
  public is_undone = false; // 该订单状态是否未完成
  public list: any[] = []; // 数据的列表
  public nav_index = 0; // nav选中的index

  // 获取所有付款详情
  getPayLogAll() {
    let _this = this;
    this.$api.request({
      url: "common/pay-data-log/get-pay-data-log-all",
      data: {
        user_id: this.user_id,
        order_id: this.order_id
      },
      success(res) {
        _this.list = res.data;
      }
    });
  }
  // 点击nav
  navClick(index) {
    this.nav_index = index;
  }
  // 预览图片
  ImagePreview(img) {
    ImagePreview([img]);
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.order_id = String(this.$route.query.order_id || "");
    this.is_undone = Boolean(this.$route.query.is_undone || false);
    this.list = [];
    this.nav_index = 0;
    this.getPayLogAll();
  }
}
